<script setup>
import axios from 'axios'
import { maska as vMaska } from 'maska'
import { computed, onMounted, reactive, ref } from 'vue'
import {
  zipLookup,
  EMAIL_REGEX,
  setEverFlow,
  getIpAddress,
  getLeadSource,
  getUserAgent,
  sanitizePhone,
  getPageReferrer,
  getJornayaLeadIdToken,
  getTrustedFormByPromise,
  ipqsCheckIfEmailInvalid,
  ipqsCheckIfPhoneIsValid,
  extractAllUrlParamsAndSetThemInVuexStore, extractUrlParams,
} from '@/js/utils'
import { useStore } from 'vuex'

import CheckIcon from '@/components/icons/CheckIcon.vue'
import ErrorIcon from '@/components/icons/ErrorIcon.vue'
import SharedComponentTCPAComplianceTextWithConsent from '@/components/questions-paths-components/shared-questions-components/SharedComponentTCPAComplianceTextWithConsent.vue'
import ThankYou from '@/components/questions-paths-components/shared-questions-components/ThankYou.vue'
import ConsentCheckBoxes from '@/components/questions-paths-components/shared-questions-components/ConsentCheckBoxes.vue'

const store = useStore()
store.commit('SET_BUYERS', [ { name: 'IntelHouse', ids: [60291] }, { name: 'Billy.com', ids: [60292] } ] , { root: true });
store.commit('SET_FIELD', { field: '1_to_1_consent', value: 'yes' }, { root: true });

const YES = 'yes'
const NO = 'no'

const SimpleAnswers = [
  {
    label: 'Yes',
    value: YES,
  },
  {
    label: 'No',
    value: NO,
  },
]

const form = reactive({
  homeowner: '',
  singleFamilyHome: '',
  lookingToReplaceWindows: '',
  numberOfWindowsYouWouldLikeReplaced: '',
})
const pii = reactive({
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  zip: '',
  city: '',
  state: '',
  county: '',
})
const efTransactionId = ref('')
const ipAddress = ref('')
const leadIdToken = ref('')
const trustedForm = reactive({
  id: '',
  url: '',
})

const homeOwnerError = ref(false)
const singleFamilyHomeError = ref(false)
const lookingToReplaceWindowsError = ref(false)
const numberOfWindowsYouWouldLikeReplacedError = ref(false)
const firstNameError = ref(false)
const lastNameError = ref(false)
const emailError = ref(false)
const phoneError = ref(false)
const zipError = ref(false)
const apiError = ref(false)

const loading = ref(false)
const completedTortData = ref(false)
const completedPiiData = ref(false)

const computedButtonText = computed(() => {
  if (loading.value) {
    return 'Loading...'
  } else if (!completedTortData.value) {
    return 'Do I Qualify?'
  } else if (completedTortData.value) {
    return 'Submit'
  }
})
const computedTortFormHasErrors = computed(() => {
  return !form.homeowner || !form.singleFamilyHome || !form.lookingToReplaceWindows || !form.numberOfWindowsYouWouldLikeReplaced
})
const computedPiiHasErrors = computed(() => {
  return firstNameError.value || lastNameError.value || emailError.value || phoneError.value || zipError.value
})
const computedTitleText = computed(() => {
  if (completedTortData.value && completedPiiData.value) {
    return 'Thank you!'
  }
  if (completedTortData.value && !completedPiiData.value) {
    return 'You qualify for a free quote!'
  } else {
    return 'Get a free quote on window replacement today!'
  }
})

const computedSubtitleText = computed(() => {
  if (completedTortData.value && completedPiiData.value) {
    return 'We will be in touch shortly'
  }
  if (completedTortData.value && !completedPiiData.value) {
    return 'Don\'t wait - Act Now'
  } else {
    return 'Want to get the best price for window replacement? Complete the form below'
  }
})

const PrimaryHomeOwner = ref([...SimpleAnswers])
const SingleFamilyHome = ref([...SimpleAnswers])
const LookingToReplaceWindows = ref([...SimpleAnswers])

function resetErrors() {
  homeOwnerError.value = false
  singleFamilyHomeError.value = false
  lookingToReplaceWindowsError.value = false
  numberOfWindowsYouWouldLikeReplacedError.value = false

  firstNameError.value = false
  lastNameError.value = false
  emailError.value = false
  zipError.value = false
  phoneError.value = false
}

function onSelectAnswer(answer, key) {
  form[key] = answer
  resetErrors()
}

function getDto(data) {
  const urlParamsArray = extractAllUrlParamsAndSetThemInVuexStore()
  const urlParams = Object.assign({}, ...urlParamsArray.map(param => ({ [param.field]: param.value })))
  const storedFields = store.state.fields

  const paramsFromUrl = extractUrlParams()
  let oid = 225

  if ('oid' in paramsFromUrl) {
    oid = paramsFromUrl.oid
  }

  return {
    ...urlParams,
    homeowner: data.homeowner.toLowerCase(),
    single_family_home: data.singleFamilyHome.toLowerCase(),
    looking_to_replace_windows: data.lookingToReplaceWindows.toLowerCase(),
    number_of_windows_you_would_like_replaced: data.numberOfWindowsYouWouldLikeReplaced.toLowerCase(),
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    phone: sanitizePhone(data.phone),
    zip: data.zip,
    city: data.city,
    state: data.state,
    county: data.county,
    ip_address: data.ipAddress,
    jornaya_id: data.leadIdToken,
    trusted_form_cert_id: data.trustedForm.id,
    trusted_form_cert_url: data.trustedForm.url,
    ef_transaction_id: data.efTransactionId,
    lp_s2: getLeadSource(),
    page_referer: getPageReferrer(),
    user_agent: getUserAgent(),
    ...storedFields,
    oid,
  }
}

function validateNames() {
  if (!pii.firstName || pii.firstName.length < 3) {
    firstNameError.value = true
  }

  if (!pii.lastName || pii.lastName.length < 3) {
    lastNameError.value = true
  }
}

async function validateEmail() {
  if (!pii.email) {
    emailError.value = true
    return
  }

  const validFormat = EMAIL_REGEX.test(String(pii.email).toLowerCase())

  if (!validFormat) {
    emailError.value = true
    return
  }

  emailError.value = await ipqsCheckIfEmailInvalid(pii.email)
}

async function validatePhone() {
  if (!pii.phone || pii.phone.length < 14) {
    phoneError.value = true
    return
  }

  const { fullValid } = await ipqsCheckIfPhoneIsValid(pii.phone)

  phoneError.value = !fullValid
}

async function validateZip() {
  if (!pii.zip || pii.zip.length < 5) {
    zipError.value = true
    return
  }

  const zipData = await zipLookup(pii.zip)

  zipError.value = !zipData.zip

  if (!zipError.value) {
    pii.city = zipData.city;
    pii.state = zipData.state;
    pii.county = zipData.county;
  }
}

async function sendData(data) {
  apiError.value = false
  loading.value = true

  const showConsentModal = await store.dispatch('shouldShowConsentModal')

  if((showConsentModal === 'showFirstConsentModal' || showConsentModal === 'showSecondConsentModal') && store.state.oneToOneConsent.countFirstModalShown === 0) {
    console.log('1')
    store.commit('SET_SHOW_CONSENT_MODAL', { modal: 'showFirstConsentModal', value: true})
    loading.value = false
    return
  }

  if (showConsentModal === 'showSecondConsentModal') {
    console.log('2')
    store.commit('SET_SHOW_CONSENT_MODAL', { modal: 'showSecondConsentModal', value: true})
    loading.value = false
    return
  }

  if (store.state.oneToOneConsent.showFirstConsentModal || store.state.oneToOneConsent.showSecondConsentModal) {
    console.log('3')
    loading.value = false
    return
  }

  await store.dispatch('saveConsentedBuyers')
  const dto = getDto(data)
  try {
    await axios.post('https://control.org31415.dev/api/leads/ingest', dto)
    completedPiiData.value = true

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'formSubmissionWindowRepair',
      eventCategory: 'Form Submission', 
      eventAction: 'Thank You Displayed',
      eventLabel: 'Conversion Detected'
    });

  } catch (e) {
    console.error('Error sending data', e)
    apiError.value = true
  } finally {
    loading.value = false
  }
}

async function onSubmit() {
  if (!completedTortData.value) {
    if (computedTortFormHasErrors.value) {
      completedTortData.value = false

      homeOwnerError.value = !form.homeowner
      singleFamilyHomeError.value = !form.singleFamilyHome
      lookingToReplaceWindowsError.value = !form.lookingToReplaceWindows
      numberOfWindowsYouWouldLikeReplacedError.value = !form.numberOfWindowsYouWouldLikeReplaced
      return
    }
    completedTortData.value = true
    return
  }

  // check pii.
  loading.value = true

  validateNames()
  await Promise.allSettled([
    validateEmail(),
    validatePhone(),
    validateZip()
  ])

  loading.value = false
  if (computedPiiHasErrors.value) return

  const data = {
    ...pii,
    ...form,
    ipAddress: ipAddress.value,
    leadIdToken: leadIdToken.value,
    trustedForm,
    efTransactionId: efTransactionId.value,
  }

  await sendData(data)
}

onMounted(() => {
  // set everflow - this is a must
  // check if oid is in the url, otherwise default to 255
  const urlParams = new URLSearchParams(window.location.search)
  const oid = urlParams.get('oid') || 225
  setEverFlow(oid)
    .then(({ ef_transaction_id }) => {
      efTransactionId.value = ef_transaction_id
    })

  // attempt to get IP
  getIpAddress()
    .then(ip => {
      ipAddress.value = ip
    })

  // attempt get jornaya
  getJornayaLeadIdToken()
    .then(token => {
      leadIdToken.value = token
    })

  // attempt to get trusted form
  getTrustedFormByPromise()
    .then(({ trustedFormId, trustedFormUrl }) => {
      trustedForm.id = trustedFormId
      trustedForm.url = trustedFormUrl
    })
})
</script>

<template>
  <div class="form-container">
    <div class="img-bg"></div>
    <div class="form-wrapper">
      <div class="top">
        <h1 class="title">
          {{ computedTitleText }}
        </h1>

        <p
          class="subheader"
          :class="{ 'yellow-bold' : completedTortData }"
        >
          {{ computedSubtitleText }}
        </p>
      </div>
      <div
        v-if="!completedTortData"
        class="bottom tort-form"
      >
        <div
          v-if="homeOwnerError || singleFamilyHomeError || lookingToReplaceWindowsError || numberOfWindowsYouWouldLikeReplacedError"
          class="message"
        >
          <ErrorIcon style="flex-shrink: 0" width="20" height="20"/>
          <p>Please fill out all fields</p>
        </div>

        <div class="form-item--container">
          <p
            class="form-item--question"
            :class="[homeOwnerError ? 'error' : '']"
          >
            Are you the primary homeowner?
          </p>

          <div class="form-item--button-inputs">
            <button
              v-for="answer in PrimaryHomeOwner"
              :key="answer.value"
              class="form-item--button"
              @click="onSelectAnswer(answer.value, 'homeowner')"
              :class="{ 'active': form.homeowner === answer.value }"
            >
              <CheckIcon v-if="form.homeowner === answer.value" class="check-icon" width="20" height="20"/>
              {{ answer.label }}
            </button>
          </div>
        </div>

        <div class="form-item--container">
          <p
            class="form-item--question"
            :class="[singleFamilyHomeError ? 'error' : '']"
          >
            Do you live in a single family home?
          </p>
          <div class="form-item--button-inputs">
            <button
              v-for="answer in SingleFamilyHome"
              :key="answer.value"
              class="form-item--button"
              @click="onSelectAnswer(answer.value, 'singleFamilyHome')"
              :class="{ 'active': form.singleFamilyHome === answer.value }"
            >
              <CheckIcon v-if="form.singleFamilyHome === answer.value" class="check-icon" width="20" height="20"/>
              {{ answer.label }}
            </button>
          </div>
        </div>

        <div class="form-item--container">
          <p
            class="form-item--question"
            :class="[lookingToReplaceWindowsError ? 'error' : '']"
          >
            Are you looking to replace your windows?
          </p>
          <div class="form-item--button-inputs">
            <button
              v-for="answer in LookingToReplaceWindows"
              :key="answer.value"
              class="form-item--button"
              @click="onSelectAnswer(answer.value, 'lookingToReplaceWindows')"
              :class="{ 'active': form.lookingToReplaceWindows === answer.value }"
            >
              <CheckIcon v-if="form.lookingToReplaceWindows === answer.value" class="check-icon" width="20" height="20"/>
              {{ answer.label }}
            </button>
          </div>
        </div>

        <div class="form-item--container">
          <p
            class="form-item--question"
            :class="[numberOfWindowsYouWouldLikeReplacedError ? 'error' : '']"
          >
            How many windows would you like replaced?
          </p>
          <select
            class="form-item--input"
            name="homeowner"
            id="homeowner"
            v-model="form.numberOfWindowsYouWouldLikeReplaced"
            @change="resetErrors"
          >
            <option value="" disabled selected>Select one</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4+">4+</option>
          </select>
        </div>
      </div>
      <div v-else-if="completedTortData && !completedPiiData" class="bottom pii-form">
        <div
          v-if="apiError"
          class="message"
        >
          <ErrorIcon style="flex-shrink: 0" width="20" height="20"/>
          <p>There was an error submitting your form, please try again.</p>
        </div>
        <div class="form-item--group">
          <div class="form-item--container has-label">
            <p
              class="form-item--question"
              :class="{ 'error' : firstNameError }"
            >
              First Name *
            </p>
            <input
              v-model.trim="pii.firstName"
              :disabled="loading"
              :class="{ 'error' : firstNameError }"
              class="form-item--input"
              type="text"
              placeholder="Enter First Name"
              @input="resetErrors"
            />
            <div
              style="height: 21px;"
              class="form-item-error-message error"
            >
              <p
                v-if="firstNameError"
                class="form-item--error-message error"
              >
                Please enter a valid first name.
              </p>
            </div>
          </div>
          <div class="form-item--container has-label">
            <p
              class="form-item--question"
              :class="{ 'error' : lastNameError }"
            >
              Last Name *
            </p>
            <input
              v-model.trim="pii.lastName"
              :disabled="loading"
              class="form-item--input"
              :class="{ 'error' : lastNameError }"
              type="text"
              placeholder="Enter Last Name"
              @input="resetErrors"
            />
            <div
              style="height: 21px;"
              class="form-item-error-message error"
            >
              <p
                v-if="lastNameError"
                class="form-item--error-message error"
              >
                Please enter a valid last name.
              </p>
            </div>
          </div>
        </div>

        <div class="form-item--group">
          <div class="form-item--container has-label">
            <p
              :class="{ 'error' : emailError }"
              class="form-item--question"
            >
              Email *
            </p>
            <input
              v-model.trim="pii.email"
              :disabled="loading"
              class="form-item--input"
              :class="{ 'error' : emailError }"
              type="text"
              placeholder="Enter Email"
              @input="resetErrors"
            />

            <div
              style="height: 21px;"
              class="form-item-error-message error"
            >
              <p
                v-if="emailError"
                class="form-item--error-message error"
              >
                Please enter a valid email.
              </p>
            </div>
          </div>
          <div class="form-item--container has-label">
            <p
              class="form-item--question"
              :class="{ 'error' : phoneError }"
            >
              Phone *
            </p>
            <input
              v-model.trim="pii.phone"
              :disabled="loading"
              class="form-item--input"
              :class="{ 'error' : phoneError }"
              type="text"
              placeholder="Enter Phone"
              v-maska="'(###) ###-####'"
              @input="resetErrors"
            />

            <div
              style="height: 21px;"
              class="form-item-error-message error"
            >
              <p
                v-if="phoneError"
                class="form-item--error-message error"
              >
                Please enter a valid phone.
              </p>
            </div>
          </div>

        </div>


        <div class="form-item--container has-label">
          <p
            class="form-item--question"
            :class="{ 'error' : zipError }"
          >
            Zip Code *
          </p>
          <input
            v-model.trim="pii.zip"
            :disabled="loading"
            class="form-item--input"
            :class="{ 'error' : zipError }"
            type="text"
            placeholder="Enter Zip Code"
            v-maska="'#####'"
            @input="resetErrors"
          />

          <div
            style="height: 21px;"
            class="form-item-error-message error"
          >
            <p
              v-if="zipError"
              class="form-item--error-message error"
            >
              Please enter a valid zip code.
            </p>
          </div>
        </div>
      </div>

      <div v-if="!completedTortData || !completedPiiData" class="submit-container">
        <button
          class="submit-button"
          :disabled="loading"
          @click="onSubmit"
        >
          {{ computedButtonText }}
        </button>

        <SharedComponentTCPAComplianceTextWithConsent v-if="completedTortData"/>
      </div>
      <div v-else-if="completedTortData && completedPiiData" class="bottom bottom--thank-you">
        <ThankYou/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../assets/style/main';


.tcpa-container {
  padding: 0 !important;
  margin: 20px 0 20px 0;
  display: block;
}


.error {
  color: red !important;
  &:is(input) {
    border: 1px solid red !important;
  }
}

.message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  width: 100%;
  padding: 10px 40px;
  border-radius: 8px;
  color: rgba(0,0,0,.87);
  background: #fecaca;
  color: red;
  border: 1px solid red;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.form-container {
  min-height: 570px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 20px;
  font-family: $font-poppins;

  @media screen and (min-width: 768px) {
    gap: 2rem;
    padding: 80px;
  }

  .img-bg {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../assets/images/hero-bg.jpg) no-repeat;
    background-position: right;
    background-size: cover;
    z-index: -1;
  }

  .header {
    font-size: 24px;
    font-weight: 600;
    color: $dark-blue;
    text-align: center;

    @media screen and (min-width: 768px) {
      font-size: 48px;
      line-height: 58px;
    }
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    height: 100%;
    margin: 0 auto;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 5px 8px 0 rgba(0,0,0,.14),0 1px 14px 0 rgba(0,0,0,.12);
    color: rgba(0,0,0,.87);
    transition: box-shadow .3s cubic-bezier(.4,0,.2,1);
    max-width: 800px;

    .top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      width: 100%;
      background-color: $red;
      padding: 20px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .title {
        text-align: center;
        font-size: 25px;
        font-weight: 700;
      }

      .subheader {
        text-align: center;
        font-size: 17px;
        font-weight: 400;

        &.yellow-bold {
          color: #fbe000;
          font-size: 25px;
          font-weight: 700;
        }
      }

      .title, .subheader {
        color: #fff;
      }

      .title span {
        color: $dark-blue
      }
    }
    .bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      width: 100%;
      padding: 20px 20px 10px 20px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background: #fff;

      &.pii-form {
        gap: 0;
      }

      &--thank-you {
        padding: 20px;
      }

      .form-item {
        &--group {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          width: 100%;

          @media screen and (min-width: 768px) {
            flex-direction: row;
          }
        }

        &--container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: 0.7rem;
          width: 100%;

          @media screen and (min-width: 768px) {
            align-items: flex-start;
          }

          &.has-label {
            align-items: flex-start;
          }
        }

        &--question {
          font-size: 18px;
          font-weight: 300;
          color: rgba(0,0,0,.87);
          text-align: center;
        }

        &--error-message {
          font-size: 14px;
        }

        &--input {
          width: 100%;
          padding: 10px;
          border: 1px solid rgba(0,0,0,.2);
          border-radius: 4px;
          font-size: 16px;
          font-weight: 400;
          color: rgba(0,0,0,.87);
          background: #fff;
          transition: border-color .3s cubic-bezier(.4,0,.2,1);
        }

        &--button-inputs {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          width: 100%;

          @media screen and (min-width: 250px) {
            flex-direction: row;
          }
        }

        &--button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.7rem;
          padding: 10px 20px;
          border: 1px solid rgba(0,0,0,.2);
          border-radius: 4px;
          font-size: 16px;
          font-weight: 400;
          transition: border-color .3s cubic-bezier(.4,0,.2,1);
          cursor: pointer;
          background: $dark-blue;
          color: #fff;
          width: 100%;
          height: 48px;

          &:hover:not(.active) {
            background: #6E93DCFF;
          }

          .check-icon {
            fill: #3FC73FFF;
          }
        }
      }
    }

    .submit-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0;
      padding: 0 20px 20px 20px;
      width: 100%;
      &.has-gap {
        gap: 20px;
      }

      .submit-button {
        margin-top: 20px;
        padding: 10px 20px;
        border: 1px solid rgba(0,0,0,.2);
        border-radius: 4px;
        font-size: 24px;
        font-weight: 400;
        transition: border-color .3s cubic-bezier(.4,0,.2,1);
        cursor: pointer;
        background: $red;
        color: #fff;
        width: 100%;
        height: 48px;

        &:hover {
          background: #e81d24;
        }
      }

    }

    @media screen and (min-width: 425px) {
      .top {
        padding: 40px;
      }
      .bottom {
        padding: 40px 40px 10px 40px;

        &--thank-you {
          padding: 40px;
        }
      }
      .submit-container {
        padding: 0 40px 40px 40px;
      }
    }
  }
}
</style>
